import "./Header.css";
import logo from "../images/logo-vectara.svg";
import { useEffect } from "react";
import { useLoginContext } from "../utils/LoginContext";
import { useNavigate } from "react-router-dom";
import { authorizeUser } from "../api/api";
import { removeAuthTokenFromStorage, setAuthTokenInStorage } from "../utils/storage";
import { GoogleSsoButton } from "../auth/GoogleSsoButton";
import { refreshJwt } from "../auth/refreshJwt";
import { VuiButtonSecondary, VuiIcon } from "@vectara/vectara-ui";
import { BiLogoGoogle } from "react-icons/bi";
import { logoutFromOry } from "../auth/oryGoogleAuth";

export const Header = () => {
  const { authToken, setAuthToken, setAuthUser, isAuthenticated, setIsAuthenticated } = useLoginContext();
  const navigate = useNavigate();

  useEffect(() => {
    // Check for an active session when the component is mounted
    const checkSession = async () => {
      const jwtToken = await refreshJwt();
      if (jwtToken) {
        attemptAuthorization(jwtToken);
      }
    };
    checkSession();
  }, [authToken]);

  const attemptAuthorization = async (jwtToken: string) => {
    try {
      const authUser = await authorizeUser(jwtToken);
      if (authUser) {
        setIsAuthenticated(true);
        setAuthUser(authUser);
        setAuthToken(jwtToken);
        setAuthTokenInStorage(jwtToken);
      }
    } catch (e) {
      clearAuth();
      console.error(e);
    }
  };

  const doLogout = async () => {
    await logoutFromOry();
    clearAuth();
    navigate("/");
  };

  const clearAuth = () => {
    setIsAuthenticated(false);
    setAuthToken("");
    removeAuthTokenFromStorage();
  };

  const renderLogin = () => {
    return !isAuthenticated ? (
      <GoogleSsoButton />
    ) : (
      <VuiButtonSecondary
        icon={
          <VuiIcon size="m">
            <BiLogoGoogle />
          </VuiIcon>
        }
        color="neutral"
        fullWidth
        onClick={doLogout}
      >
        Log out
      </VuiButtonSecondary>
    );
  };

  return (
    <nav className="flex items-center justify-between flex-wrap bg-purple-600 p-6 mb-5">
      <div className="flex items-center flex-shrink-0 text-white mr-6">
        <img onClick={() => navigate("/")} className="logo-image" alt="Search" width="54" height="54" src={logo}></img>
        <span className="font-semibold text-xl tracking-tight">Vectara Command & Control Center</span>
      </div>
      <div>{renderLogin()}</div>
    </nav>
  );
};
